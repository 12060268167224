<template>
  <Layout>

    <div class="row mt-4 mb-4">
      <div class="col-lg-9">
        <div>
          <h4 class="card-title">Фильтры</h4>
          <div class="row">
            <div class="col-4 position-relative">
              <i class="pt-1 pl-1 ri-search-line position-absolute z-1"></i>
              <input v-model="searchString" type="text" class="form-control pl-4 rounded-pill border-0 form-control-sm"
                     :placeholder="'Номер заказа'">
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-lg-3">
              <div class="form-group">
                <label for="">Что печатали</label>
                <select v-model="filterType" class="form-control form-control-sm">
                  <option value="">-</option>
                  <option value="1">Фото</option>
                  <option value="2">Доки</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 text-right clear-filters">
        <div @click="clearAllFilter" class="btn btn-sm btn-secondary">Сбросить фильтры</div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">Сапорт</div>

            <div class="row mt-3">
              <div class="col-lg-12">
                <table class="table table-hover" style="cursor: pointer;">
                  <thead>
                  <tr>
                    <th>ID</th>
                    <th>Создано</th>
                    <th>Заказ</th>
                    <th>Тип обращения</th>
                    <th>Описание</th>
                    <th>Тип заказа</th>
                    <th>Тип услуги</th>
                    <th>Адрес</th>
                    <th>Стоимость</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in tickets" :key="item.id">
                    <td>{{item.id}}</td>
                    <td>{{item.created_at}}</td>
                    <td>{{item.order_id}}</td>
                    <td>{{item.reason.description}}</td>
                    <td>{{item.description}}</td>
                    <td>{{item.order.type}}</td>
                    <td>{{item.order.service_type_id == 1 ? 'фото' : 'сервис'}}</td>
                    <td>{{item.order.delivery_address ? item.order.delivery_address : item.order.printer.printerLocation.address}}</td>
                    <td>{{item.order.price}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>

  </Layout>
</template>


<script>
import Layout from "../../layouts/main";
import axios from "axios";
import CopyArrayAndObject from "@/components/libraries/CopyArrayAndObject";

const copyArrObj = new CopyArrayAndObject();

/**
 * Reviews component
 */
export default {
  components: {
    Layout,
  },
  data() {
    return {
      filterType: '',
      tickets: [],
      searchString: '',
      clickedNotes: "",
      blockFilterWatch: false,
      searchStrTimeout: false,
      submitted: false,
      title: this.$t('menuitems.management.list.support'),
      items: [
        {
          text: this.$t('menuitems.management.text'),
        },
        {
          text: this.$t('menuitems.management.list.support'),
          active: true
        }
      ],
    };
  },
  watch: {
    filterType: function () {
      if(!this.blockFilterWatch){
        //this.setPage(1); // reset page on apply filters
        this.getTickets();
      }
    },
    searchString: function() {
      clearTimeout(this.searchStrTimeout);
      if(!this.blockFilterWatch){
        this.searchStrTimeout = setTimeout(() => {
          //this.setPage(1); // reset page on apply filters
          this.getTickets();
        }, 2000);
      }
    },
  },
  validations: {

  },
  methods: {
    getTickets(){
      axios
          .post(`/v1/tickets/get-all?expand=order.printer.printerLocation,reason&filterType=${this.filterType}&orderId=${this.searchString}`)
          .then(resp => {
            this.tickets = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    async clearAllFilter(){
      this.blockFilterWatch = true;
      this.filterType = '';
      this.searchString = '';
      await this.loadList().then(() => {
        this.blockFilterWatch = false;
      });
    },
  },
  created() {
    this.getTickets();
  }
};
</script>

<style scoped lang="scss">
  .search{
    margin-right: 15px;
  }
</style>
